import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, scanOutline, helpOutline, searchOutline } from 'ionicons/icons';
import Tab1 from './pages/Tab1_Explore';
import Scan from './pages/Scan';
import Tab3 from './pages/Tab3_About';
import Stalbans from './pages/locations/stalbans';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Northherts from './pages/locations/northherts';
import Stevenage from './pages/locations/stevenage';
import Watford from './pages/locations/watford';
import Royston from './pages/locations/royston';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/explore">
            <Tab1 />
          </Route>
          <Route exact path="/scan/:id" component={Scan} />
          <Route path="/about">
            <Tab3 />
          </Route>
          <Route path="/explore/stalbans">
            <Stalbans />
          </Route>
          <Route path="/explore/stevenage">
            <Stevenage />
          </Route>
          <Route path="/explore/northherts">
            <Northherts />
          </Route>
          <Route path="/explore/watford">
            <Watford/>
          </Route>
          <Route path="/explore/royston">
            <Royston/>
          </Route>
          <Route exact path="/">
            <Redirect to="/explore" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/explore">
            <IonIcon icon={searchOutline} />
            <IonLabel>Explore</IonLabel>
          </IonTabButton>
          {/* 
          <IonTabButton tab="tab2" href="/scan">
            <IonIcon icon={scanOutline} />
            <IonLabel>Scan</IonLabel>
          </IonTabButton>
          */}
          <IonTabButton tab="tab3" href="/about">
            <IonIcon icon={helpOutline} />
            <IonLabel>About</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
