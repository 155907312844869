import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonListHeader } from '@ionic/react';
import './Tab1_Explore.css';

const Tab1: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Explore</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Explore</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>

      {/* <IonItem button onClick={() => console.log("royston")}>
        <IonLabel>The Royston and District Museum and Art Gallery</IonLabel>
      </IonItem>
      <IonItem button onClick={() => console.log("northerts")}>
        <IonLabel>North Hertfordshire Museum</IonLabel>
      </IonItem>
      <IonItem button onClick={() => console.log("stevenage")}>
        <IonLabel>Stevenage Museum</IonLabel>
      </IonItem> 
      
      <IonItem button onClick={() => console.log("watford")}>
        <IonLabel>Watford Museum</IonLabel>
      </IonItem>
      
      */}
      
      <IonItem button href="/explore/stalbans" onClick={() => console.log("stalbans")}>
        <IonLabel>St Albans Museum + Gallery</IonLabel>
      </IonItem>

      <IonItem button href="/explore/northherts" onClick={() => console.log("northherts")}>
        <IonLabel>North Hertfordshire Museum</IonLabel>
      </IonItem>

      <IonItem button href="/explore/stevenage" onClick={() => console.log("stevenage")}>
        <IonLabel>Stevenage Museum</IonLabel>
      </IonItem>

      <IonItem button href="/explore/watford" onClick={() => console.log("watford")}>
        <IonLabel>Watford Museum</IonLabel>
      </IonItem>

      <IonItem button href="/explore/royston" onClick={() => console.log("royston")}>
        <IonLabel>Royston Museum</IonLabel>
      </IonItem>
      
    </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Tab1;
