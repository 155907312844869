import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab2_Scan.css';
import {RouteComponentProps} from 'react-router';

import { ARObjects } from "./locations/data";
import ARModel from '../components/ARModel';

interface ScanPageProps extends RouteComponentProps<{
    id: string;
  }> {}

const Scan: React.FC<ScanPageProps> = ({ match }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scan {match.params.id}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      {(ARObjects.filter(ARObject => ARObject.id === match.params.id)).map((data, key) => {
          return (
            <ARModel 
                src={data.src}
                ios={data.ios}
                poster={data.poster}
                title={data.title}
                subtitle={data.subtitle}
                content={data.content}
                youtubeID={data.youtubeID}
            />
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default Scan;
