export const ARObjects = [
    {
        id: "000",
        install: "hidden",
        visible: true,
        //src: "https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b/Astronaut.glb?1542147958948",
        src: "https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b/Astronaut.glb?1542147958948",
        ios: "https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b/Astronaut.usdz?v=1569545377878",
        poster: "https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b%2Fposter-astronaut.png?v=1599079951717",
        title: "Test 1A",
        subtitle: "",
        content: "",
        youtubeID: "XCbAEkfXSDE"
    },
    {
        id: "100",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Charlotte.reality",
        poster: "/assets/stalbans/Charlotte.png",
        title: "Charlotte",
        subtitle: "",
        content: "",
        youtubeID: "2zFsboxMKS0"
    },
    {
        id: "101",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Emir.reality",
        poster: "/assets/stalbans/Emir.png",
        title: "Emir",
        subtitle: "",
        content: "",
        youtubeID: "nh5oONQ8puY"
    },
    {
        id: "102",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Kiera.reality",
        poster: "/assets/stalbans/Kiera.png",
        title: "Keira",
        subtitle: "",
        content: "",
        youtubeID: "99MF6Fox63c"
    },
    {
        id: "103",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "https://cf-ogma.s3.eu-west-2.amazonaws.com/Lilly.reality",
        poster: "/assets/stalbans/Lilly.png",
        title: "Lilly",
        subtitle: "",
        content: "",
        youtubeID: "lzN64ue-LW8"
    },
    {
        id: "104",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Lottie.reality",
        poster: "/assets/stalbans/Lottie.png",
        title: "Lottie",
        subtitle: "",
        content: "",
        youtubeID: "7QRo5ALtDhY"
    },
    {
        id: "105",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Priya.reality",
        poster: "/assets/stalbans/priya.png",
        title: "Priya",
        subtitle: "",
        content: "",
        youtubeID: "vAcEqfIhIrc"
    },
    {
        id: "106",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/sam.reality",
        poster: "/assets/stalbans/sam.png",
        title: "Sam",
        subtitle: "",
        content: "",
        youtubeID: "f4fOnzvydKI"
    },
    {
        id: "107",
        install: "stalbans",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Tia.reality",
        poster: "/assets/stalbans/Tia.png",
        title: "Tia",
        subtitle: "",
        content: "",
        youtubeID: "h1c6_AtW2rM"
    },
    {
        id: "300",
        install: "northherts",
        visible: true,
        src: "",
        ios: "",
        poster: "",
        title: "Group Performance",
        subtitle: "",
        content: "",
        youtubeID: "CM0PrUR9tYE"
    },
    {
        id: "301",
        install: "northherts",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Tia_NorthHerts.reality",
        poster: "/assets/stalbans/Tia_NorthHerts.png",
        title: "Tia",
        subtitle: "",
        content: "",
        youtubeID: "62QVS3x5N-I"
    },
    {
        id: "302",
        install: "northherts",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Libby2.reality",
        poster: "/assets/stalbans/Libby.png",
        title: "Libby",
        subtitle: "",
        content: "",
        youtubeID: "oNUYToxgydg"
    },
    {
        id: "303",
        install: "northherts",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Katie.reality",
        poster: "/assets/stalbans/Katie.png",
        title: "Katie",
        subtitle: "",
        content: "",
        youtubeID: "c8b7v1deIjs"
    },
    {
        id: "304",
        install: "northherts",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Ella.reality",
        poster: "/assets/stalbans/Ella.png",
        title: "Ella",
        subtitle: "",
        content: "",
        youtubeID: "PsiyARs_HxU"
    },
    {
        id: "305",
        install: "northherts",
        visible: true,
        src: "",
        ios: "",
        poster: "",
        title: "Denaé",
        subtitle: "",
        content: "",
        youtubeID: "MDy_Ey0lvAI"
    },
    {
        id: "400",
        install: "stevenage",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Catrinel.reality",
        poster: "/assets/stalbans/Catrinel.jpeg",
        title: "Catrinel",
        subtitle: "",
        content: "",
        youtubeID: "f8P6rMhsIZQ"
    },
    {
        id: "401",
        install: "stevenage",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Isobel.reality",
        poster: "/assets/stalbans/Isobel.png",
        title: "Isobel",
        subtitle: "",
        content: "",
        youtubeID: "mpxdqYQJI5I"
    },
    {
        id: "402",
        install: "stevenage",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Megan.reality",
        poster: "/assets/stalbans/Megan.png",
        title: "Megan",
        subtitle: "",
        content: "",
        youtubeID: ""
    },
    {
        id: "403",
        install: "stevenage",
        visible: true,
        src: "",
        ios: "/assets/stalbans/rita.reality",
        poster: "/assets/stalbans/Rita.png",
        title: "Rita",
        subtitle: "",
        content: "",
        youtubeID: "ywSkR6KOMEY"
    },
    {
        id: "404",
        install: "stevenage",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Robert.reality",
        poster: "/assets/stalbans/robert.png",
        title: "Robert",
        subtitle: "",
        content: "",
        youtubeID: ""
    },
    {
        id: "500",
        install: "watford",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Ava.reality",
        poster: "/assets/stalbans/Ava.png",
        title: "Ava",
        subtitle: "",
        content: "",
        youtubeID: "8bpxLDX-ZzY"
    },
    {
        id: "501",
        install: "watford",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Emily.reality",
        poster: "/assets/stalbans/Emily.png",
        title: "Emily",
        subtitle: "",
        content: "",
        youtubeID: "W-qTul7DFpo"
    },
    {
        id: "502",
        install: "watford",
        visible: true,
        src: "/assets/stalbans/Joshua.glb",
        ios: "/assets/stalbans/Joshua.reality",
        poster: "/assets/stalbans/Joshua.png",
        title: "Joshua",
        subtitle: "",
        content: "",
        youtubeID: "bdI3MUBn7dU"
    },
    {
        id: "503",
        install: "watford",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Alex.reality",
        poster: "/assets/stalbans/Alex.png",
        title: "Alex",
        subtitle: "",
        content: "",
        youtubeID: "W-vfVJxkaA0"
    },
    {
        id: "504",
        install: "watford",
        visible: true,
        src: "",
        ios: "https://cf-ogma.s3.eu-west-2.amazonaws.com/Oliver.reality",
        poster: "/assets/stalbans/Oliver.png",
        title: "Oliver",
        subtitle: "",
        content: "",
        youtubeID: "yBrCn8pKCjw"
    },
    {
        id: "505",
        install: "watford",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Zaria.reality",
        poster: "/assets/stalbans/Zaria.png",
        title: "Zaria",
        subtitle: "",
        content: "",
        youtubeID: "6uHYjEI0KAw"
    },
    {
        id: "200",
        install: "royston",
        visible: true,
        src: "",
        ios: "/assets/stalbans/jack.reality",
        poster: "/assets/stalbans/Jack.png",
        title: "Jack",
        subtitle: "",
        content: "",
        youtubeID: "LSZJKnK5wUM"
    },
    {
        id: "201",
        install: "royston",
        visible: true,
        src: "",
        ios: "/assets/stalbans/Jacobsticks2.reality",
        poster: "/assets/stalbans/Jacob.png",
        title: "Jacob",
        subtitle: "",
        content: "",
        youtubeID: "LcU_jlqLvLg"
    },
    {
        id: "202",
        install: "royston",
        visible: true,
        src: "",
        ios: "/assets/stalbans/samRoyston.reality",
        poster: "/assets/stalbans/samRoyston.png",
        title: "Sam",
        subtitle: "",
        content: "",
        youtubeID: "TT0GiHMcvXE"
    },

]