import { 
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent
} from '@ionic/react';

import './ExploreContainer.css';
import '@google/model-viewer';
import { url } from 'inspector';
import YouTube from 'react-youtube';

interface ContainerProps {
  src: string;
  ios: string;
  poster: string;
  title: string;
  subtitle: string;
  content: string;
  youtubeID: string;
}

const ARModel: React.FC<ContainerProps> = ({ src, ios, poster, title, subtitle, content, youtubeID }) => {
  return (
        <IonCard>
        
            <IonCardHeader>
            <IonCardSubtitle>{subtitle}</IonCardSubtitle>
            <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>
            <model-viewer src={src}
                  ios-src={ios}
                  poster={poster}
                  alt={title}
                  shadow-intensity="1"
                  camera-controls
                  auto-rotate ar>
    </model-viewer>
            <IonCardContent>
              {content}
              <YouTube opts={{width: '320',height: '195'}} videoId={youtubeID} />
            </IonCardContent>
        </IonCard>
  );
};

export default ARModel;
