import { 
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar, 
    IonList, 
    IonItem, 
    IonLabel, 
    IonListHeader,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent
} from '@ionic/react';

import '../Tab1_Explore.css';

import { ARObjects } from "./data";
import ARModel from '../../components/ARModel';

const Northherts: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
                <IonBackButton defaultHref="/explore"></IonBackButton>
            </IonButtons>
          <IonTitle>North Hertfordshire Museum</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      
        {(ARObjects.filter(ARObject => (ARObject.install === "northherts" && ARObject.visible === true))).map((data, key) => {
          return (
            <ARModel 
                src={data.src}
                ios={data.ios}
                poster={data.poster}
                title={data.title}
                subtitle={data.subtitle}
                content={data.content}
                youtubeID={data.youtubeID}
            />
          );
        })}
        
      </IonContent>
    </IonPage>
  );
};

export default Northherts;
